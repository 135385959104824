// eslint-disable
import React from 'react'
import _ from 'lodash'
import { BRS_tanggalIndo } from '../../util'
import { BsGraphUp, BsFolderCheck, BsFillCloudArrowDownFill, BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { DotLoader } from 'react-spinners';
import { useContext } from 'react';
import { HostContext } from '../../context';
const Section_statistik = () => {
  const { HOST } = useContext(HostContext)
  const [data, set_data] = React.useState([])
  const [data_detail, set_data_detail] = React.useState([])
  const [daftar_tahun, set_tahun] = React.useState([])
  const [id_loading, set_id_loading] = React.useState(0)
  const [id_dinas, set_id_dinas] = React.useState(0)
  const [id_kategori, set_id_kategori] = React.useState(0)
  const [daftar_dinas, set_daftar_dinas] = React.useState([])
  const [daftar_subjek, set_daftar_subjek] = React.useState([])
  const [loading, set_loading] = React.useState(false)
  const [loading_detail, set_loading_detail] = React.useState(false)
  const [data_semua, set_data_semua] = React.useState(true)
  const [page, set_page] = React.useState(1)

  React.useEffect(() => {
    const getDaftarDinas = async () => {
      const requ = await fetch(`${HOST}/api/fdone/daftar_sumber`)
      try {
        const resu = await requ.json()
        if (resu.success) {
          set_daftar_dinas(resu?.data==="null" ? resu.data : [])
        } else {
          console.log('log_disini', resu)
        }
      } catch (err) {
        alert('statistik:', err.message)
      }
    }
    const getDaftarSubjek = async () => {
      const requ = await fetch(`${HOST}/api/fdone/daftar_kategori`)
      try {
        const resu = await requ.json()
        if (resu.success) {
          set_daftar_subjek(resu?.data==="null" ? resu.data : [])
        } else {
          alert(resu.message)
        }
      } catch (err) {
        alert(err.message)
      }
    }

    const getResponse = async () => {
      set_loading(true)
      const requ = await fetch(`${HOST}/api/fdone/data`, { method: 'GET' })
      if (!requ.ok) { return alert('Koneksi ke server BPS sulut Bermasalah.!') }
      try {
        const resl = await requ.json()
        if(resl.success){
          set_data(resl.data === "null" ? resl.data : [])
        }
        const filterTahun = _.map(resl.data, 'tahundata')
        const filterTahunUnique = _.uniq(filterTahun)
        set_tahun(filterTahunUnique)
        set_loading(false)
      } catch (error) {
        console.log('error disini', error.message)
        set_loading(false)
      }
    }
    getDaftarDinas()
    getDaftarSubjek()
    getResponse()
  }, [HOST])

  const handleFilterDinas = (e) => {
    let id = e.target.value
    set_id_dinas(id)
    set_id_kategori("")
    set_loading(true)
    const getResponse = async () => {
      let requ;
      if (id === "all") {
        requ = await fetch(`${HOST}/api/fdone/data`)
      } else {
        requ = await fetch(`${HOST}/api/fdone/data/sumber/${id}`)
      }
      if (!requ.ok) { return alert('Koneksi ke server FORDONE Bermasalah.!') }
      try {
        const resu = await requ.json()
        set_data(resu?.data==="null" ? resu.data : [])
        set_loading(false)
      } catch (error) {
        console.log('error disini', error.message)
        set_loading(false)
      }
    }
    getResponse()
  }

  const handleFilterKategori = (e) => {
    let id = e.target.value
    set_id_kategori(id)
    set_id_dinas("")
    set_loading(true)
    const getResponse = async () => {
      let requ;
      if (id === "all") {
        requ = await fetch(`${HOST}/api/fdone/data`)
      } else {
        requ = await fetch(`${HOST}/api/fdone/data/kategori/${id}`)
      }
      if (!requ.ok) { return alert('Koneksi ke server BPS sulut Bermasalah.!') }
      try {
        const resu = await requ.json()
        set_data(resu?.data==="null" ? resu.data : [])
        set_loading(false)
      } catch (error) {
        console.log('error disini,', error.message)
        set_loading(false)
      }

    }
    getResponse()
  }

  const handleViewDetail = (id) => {
    set_id_loading(id)
    set_loading_detail(true)
    const getResponseDetail = async () => {
      const requ = await fetch(`${HOST}/api/fdone/data/${id}`, { method: 'GET' })
      try {
        const resu = await requ.json()
        set_data_detail(resu?.data==="null" ? resu.data : [])
        set_data_semua(e => !e)
        set_loading_detail(false)
      } catch (error) {
        console.log('error detail', error.message)
        set_loading_detail(false)
      }
    }
    getResponseDetail()

  }

  const handleCleanTable = (string) => {
    let newString;
    newString = string.replaceAll('&nbsp;', '')
    newString = newString.replaceAll('id="textarea_tabellewis"', '')
    newString = newString.replaceAll('class="form-control"', '')
    newString = newString.replaceAll('contenteditable=""', '')
    newString = newString.replaceAll('<div   >', '')
    newString = newString.replaceAll('</div>', '')
    newString = newString.replaceAll('id="lewistabel"', 'class="view-detail"')
    return newString;
  }

  const handlePrevPage = async () => {
    set_loading(true)
    set_page(e => e <= 1 ? 1 : e - 1)
    const requ = await fetch(`${HOST}/api/fdone/data/halaman/${page - 1}`, { method: 'GET' })
    try {
      const resu = await requ.json()
      if (resu.success) {
        set_data(resu.data)
        set_loading(false)
      } else {
        alert(resu.message)
      }
      set_loading(false)
    } catch (err) {
      alert(err.message)
      set_loading(false)
    }
  }

  const handleNextPage = async () => {
    set_loading(true)
    set_page(e => e + 1)
    const requ = await fetch(`${HOST}/api/fdone/data/halaman/${page + 1}`, { method: 'GET' })
    try {
      const resu = await requ.json()
      if (resu.success) {
        set_data(resu?.data==="null" ? resu.data : [])
        set_loading(false)
      } else {
        alert(resu.message)
        set_loading(false)
      }
    } catch (err) {
      alert(err.message)
      set_loading(false)
    }
  }

  const handleView = (data_semua, data_detail) => {
    const view = data_semua
      ?
      <>
        <table className='table table-striped table-bordered shadow-sm' style={{ fontSize: "12px" }}>
          <thead className="bg-thead-view">
            <tr>
              <th colSpan="3">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="tahun">FILTER BERDASARKAN TAHUN DATA</label>
                      <select className='form-control' id='tahun'>
                        <option>-Pilih-</option>
                        {daftar_tahun.map((val, ind) => { return <option value={val} key={ind}>{val}</option> })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="dinas">FILTER BERDASARKAN SUMBER</label>
                      <select className='form-control' id='dinas'
                        onChange={handleFilterDinas} value={id_dinas}>
                        <option>-Pilih-</option>
                        <option value="all">Semua Dinas</option>
                        {daftar_dinas.map(val => { return <option value={val.id} key={val.id}>{val.nama}</option> })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="kategori">FILTER BERDASARKAN KATEGORI</label>
                      <select className='form-control' id='kategori'
                        onChange={handleFilterKategori} value={id_kategori}>
                        <option>-Pilih-</option>
                        <option value="all">Semua Kategori</option>
                        {daftar_subjek.map(val => { return <option value={val.id} key={val.id}>{val.namasubjek}</option> })}
                      </select>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th colSpan="3" className="text-center">Menampilkan <strong>{data.length}</strong> Dataset Pada Halaman {page}</th>
            </tr>
          </thead>
          <tbody>
            {
              !loading
                ?

                data.length !== 0
                  ? data.map((val, ind) => {
                    return <tr key={ind}>
                      <td width="2%">
                        <BsGraphUp style={{ fontSize: 32 }} />
                      </td>
                      <td>
                        <p style={{ fontSize: '14px' }}>{val.judul}</p>
                        <span className='text-muted badge bg-light' style={{ fontSize: '12px' }}>{val.updatetanggal}</span>
                      </td>
                      {
                        id_loading === val.id
                          ?
                          loading_detail
                            ?
                            <td width="5%">
                              <button className='btn btn-sm btn-primary' disabled><span className="spinner-border spinner-grow-sm" role="status" aria-hidden="true"></span></button>
                            </td>
                            :
                            <td width="5%"><button className='btn btn-outline-primary' onClick={handleViewDetail.bind(this, val.id)}><BsFolderCheck /></button></td>
                          :
                          <td width="5%"><button className='btn btn-outline-primary' onClick={handleViewDetail.bind(this, val.id)}><BsFolderCheck /></button></td>
                      }
                    </tr>
                  })
                  :
                  <tr><td colSpan={3} className='text-center'>Dataset Tidak Tersedia.</td></tr>
                :
                <tr>
                  <td rowSpan={3}>
                    <div className="text-center">
                      <div className="spinner-border" role="status"></div> <span className="visually-hidden"></span> <br />
                      <span>Mohon Tunggu...</span>
                    </div>
                  </td>
                </tr>
            }
          </tbody>
        </table>
        <button className='btn' onClick={handlePrevPage}><BsArrowLeftCircleFill /> Sebelumnya</button>
        <button className='btn mx-2' onClick={handleNextPage}>Selanjutnya <BsArrowRightCircleFill /></button>
      </>
      :
      <>
        <div className="row">
          <div className="col-md-12">
            <h2>{data_detail.judul.toUpperCase()}</h2>
            <span className='badge text-white' style={{ backgroundColor: '#3c2323' }}>Data tahun: {data_detail.tahundata}</span>
            <span className='badge bg-dark text-white mx-2'>Diupdate pada : {BRS_tanggalIndo(data_detail.updatetanggal)}</span>
            {/* <span className='badge text-white mx-2' style={{ backgroundColor: '#113567' }}>Sumber data: {daftar_dinas[_.findIndex(daftar_dinas, ['id', data_detail.iddinas])].dinas}</span> */}

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div dangerouslySetInnerHTML={{ __html: _.trim(handleCleanTable(data_detail.isi)) }} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <button className='btn' onClick={() => set_data_semua(e => !e)}><BsArrowLeftCircleFill /> Kembali ke List</button>
            <a className='btn mx-2' href={data_detail._links.download_link.href} target="_blank" rel='noreferrer'><BsFillCloudArrowDownFill /> Download Data</a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            Catatan:
            <textarea className='form-control' rows={3} disabled defaultValue={data_detail.catatan ? data_detail.catatan : 'Tidak ada catatan tersedia.'}></textarea>
          </div>
        </div>
      </>
    return view
  }

  return (
    <section id="statistik" className='statistik'>
      <div className="container">
        <div className="section-title">
          <img src="./assets/img/logo-bps.png" alt='logo' width={"10%"} />
          <h2 className="text-dark">Data Statistik BPS Kotamobagu</h2>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {
              !loading ?
                handleView(data_semua, data_detail) :
                <div className='d-flex justify-content-center'>
                  <DotLoader size={64} />
                </div>
            }
          </div>
        </div>
      </div>
    </section>
  )

}

export default Section_statistik