import React, { useContext, useCallback, useEffect, useState } from 'react'
import { DotLoader } from 'react-spinners';
import { HostContext } from '../../context';
import DATA_PAGINATION from './section_edatabase_pagination';
const Section_edatabase = () => {

  const {HOST} = useContext(HostContext)

  const [data_gambaran_umum, set_data_gambaran_umum] = useState([])
  const [keyword, set_keyword] = useState("")
  const [data_iku, set_data_iku] = useState([])
  const [data_kor, set_data_kor] = useState([])

  const [loading, set_loading] = useState(false)

  const [data_tahun] = useState(["2016", "2017", "2018", "2019", "2020", "2021"])
  const [data_tahun_iku] = useState(["2016", "2017", "2018", "2019", "2020", "x021"])
  const [data_tahun_kor] = useState(["2017", "2018", "2019", "2020", "2021"])

  const handleIndex01 = useCallback(async () => {
    set_loading(true)
    set_keyword("")
    const requ = await fetch(`${HOST}/api/sipd/data/gambaran_umum`, { method: 'GET' })
    try {
      const resu = await requ.json()
      set_data_gambaran_umum(resu.data.indikator)
      set_loading(false)
    } catch (err) {
      console.log(err.message)
      set_loading(false)
    }
  }, [HOST])

  const handleIndex02 = async () => {
    set_loading(true)
    set_keyword("")
    const requ = await fetch(`${HOST}/api/sipd/data/iku`, { method: 'GET' })
    try {
      const resu = await requ.json()
      set_data_iku(resu.data.indikator||[])
      set_loading(false)
    } catch (err) {
      console.log(err.message)
      set_loading(false)
    }
  }

  const handleIndex03 = async () => {
    set_loading(true)
    set_keyword("")
    const requ = await fetch(`${HOST}/api/sipd/data/kor`, { method: 'GET' })
    try {
      const resu = await requ.json()
      set_data_kor(resu.data.indikator||[])
      set_loading(false)
    } catch (err) {
      console.log(err.message)
      set_loading(false)
    }
  }

  useEffect(() => {
    handleIndex01()
  }, [handleIndex01])

  return (
    <section id="edatabase" className='edatabase'>
      <div className="container">
        <div className="section-title">
          <img src="./assets/img/logo-sipd.png" alt='logo' width={"10%"} />
          <h2 className="text-dark">e-DATABASE SIPD</h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation" onClick={handleIndex01}>
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#index01" type="button"
                  role="tab" aria-controls="index01" aria-selected="true">INDIKATOR GAMBARAN UMUM</button>
              </li>
              <li className="nav-item" role="presentation" onClick={handleIndex02}>
                <button className="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#index02" type="button"
                  role="tab" aria-controls="index02" aria-selected="true">INDIKATOR KINERJA UTAMA (IKU)</button>
              </li>
              <li className="nav-item" role="presentation" onClick={handleIndex03}>
                <button className="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#index03" type="button"
                  role="tab" aria-controls="index03" aria-selected="true">INDIKATOR STATISTIK SEKTORAL UTAMA (KOR)</button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button className="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#index04" type="button"
                  role="tab" aria-controls="index04" aria-selected="true">INDIKATOR DATA DAERAH</button>
              </li> */}
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="index01" role="tabpanel" aria-labelledby="index01" tabIndex="0">
                <div className="mt-2">

                  {
                    loading
                      ?
                      <div className='d-flex justify-content-center mt-2'>
                        <DotLoader />
                      </div>

                      :
                      <>
                        <div className="col-md-12">
                          <input type={"text"} placeholder={"Kata kunci"} className="form-control" onChange={(e) => set_keyword(e.target.value)} />
                        </div>
                        <DATA_PAGINATION keyword={keyword} itemsPerPage={15} items={data_gambaran_umum} type={"type_gambaran_umum"} tahun={data_tahun} />
                      </>

                  }

                </div>
              </div>
              <div className="tab-pane fade show" id="index02" role="tabpanel" aria-labelledby="index02" tabIndex="0">
                <div className="mt-2">

                  {
                    loading
                      ?
                      <div className='d-flex justify-content-center mt-2'>
                        <DotLoader />
                      </div>

                      :
                      <>
                        <div className="col-md-12">
                          <input type={"text"} placeholder={"Kata kunci"} className="form-control" onChange={(e) => set_keyword(e.target.value)} />
                        </div>
                        <DATA_PAGINATION keyword={keyword} itemsPerPage={15} items={data_iku} type={"type_iku"} tahun={data_tahun_iku} />
                      </>

                  }
                </div>
              </div>
              <div className="tab-pane fade show" id="index03" role="tabpanel" aria-labelledby="index03" tabIndex="0">
                <div className="mt-2">
                  {
                    loading
                      ?
                      <div className='d-flex justify-content-center mt-2'>
                        <DotLoader />
                      </div>

                      :
                      <>
                        <div className="col-md-12">
                          <input type={"text"} placeholder={"Kata kunci"} className="form-control" onChange={(e) => set_keyword(e.target.value)} />
                        </div>
                        <DATA_PAGINATION keyword={keyword} itemsPerPage={15} items={data_kor} type={"type_kor"} tahun={data_tahun_kor} />
                      </>
                  }

                </div>
              </div>
              <div className="tab-pane fade show" id="index04" role="tabpanel" aria-labelledby="index04" tabIndex="0">
                <div className="mt-2">
                  <h1 className='text-center'>Data belum tersedia</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section_edatabase